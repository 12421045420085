const selectors = {
  submit: '[data-footer-newsletter-submit]',
  input_wrapper: '[data-footer-newsletter-input-wrapper]',
  message: '[data-footer-newsletter-message]',
};

class FooterNewletterSignup extends HTMLElement {
  constructor() {
    super();

    this.submitBtns = document.querySelectorAll(selectors.submit);
    this.inputWrappers = document.querySelectorAll(selectors.input_wrapper);
    this.messages = document.querySelectorAll(selectors.message);

    this.checkSignedUp();
    this.initEventListener();
  }

  initEventListener() {
    this.submitBtns.forEach(btn => {
      btn.addEventListener('click', () => {
        const input = btn.previousElementSibling.value;
        const validate = this.validate(input);

        if (validate) {
          this.showMessage('confirm');
          document.cookie = 'newsletterSignedUp=1';

          setTimeout(() => {
            btn.closest('form').submit();
            document.location.reload();
          }, 2000);
        } else {
          this.showMessage('error');
        }
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  showMessage(message) {
    const errorMessage = 'The email format is incorrect';

    if (message === 'confirm') {
      this.messages.forEach(msg => {
        const confirmMessage = msg.dataset.footerNewsletterMessage;
        // eslint-disable-next-line no-param-reassign
        msg.innerHTML = confirmMessage;
      });
    } else {
      this.messages.forEach(msg => {
        // eslint-disable-next-line no-param-reassign
        msg.innerHTML = errorMessage;
      });
    }
  }

  checkSignedUp() {
    if (
      document.cookie
        .split(';')
        .some(item => item.includes('newsletterSignedUp=1'))
    ) {
      this.showMessage('confirm');
      this.hideInputs();
    }
  }

  hideInputs() {
    this.inputWrappers.forEach(wrap => {
      wrap.classList.add('!hidden');
    });
  }

  // eslint-disable-next-line class-methods-use-this
  validate(email) {
    const regex =
      /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()\\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }
}

customElements.define('footer-newsletter-signup', FooterNewletterSignup);
